import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { EffectFade, Autoplay, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';

function Hero() {
  return (
    <section className="hero text-center text-black py-3 text-white">

          <Swiper
              modules={[EffectFade, Autoplay, Pagination]}
              spaceBetween={10}
              loop={true}
              speed={3200}
              autoplay={{
                delay: 3700,
                disableOnInteraction: false,
              }}
              className="swiper-container"
            >
              <SwiperSlide key="main-image">
              <div className="container hero-promo rounded bg-black d-flex">
                <div className="container hero-promo-slide rounded d-flex">
                  <h1 className="display-4 promo-h1 mb-8">Студия Цветов</h1>
                  <h2 className="mb-4 promo-h4 mb-8">Цветы - это больше, чем слова!</h2>
                  <h3 className="display-6 promo-h3 mb-8">Floristel в Королёве</h3>
                </div>
              </div>
              </SwiperSlide>
              <SwiperSlide key="main-image">
                <Link to="/discountpage">
                  <div className="container mothers-day rounded bg-black d-flex">
                    <div className="container rounded  d-flex align-items-end justify-content-evenly">
                      <div className='mother-hero-text d-flex align-self-start'>
                        <h1 className="display-4 mother-title promo-h1 mb-8 text-dark">Дарите тепло этой зимой – выбирайте яркие букеты!</h1>
                        <h2 className="mb-2 mother-text promo-h4 mb-8 fs-6 text-dark">Зимние букеты уже здесь! Выбирайте цветочное тепло, которое согреет сердце в холодные дни!</h2>
                        <div className='mother-order'>
                          <p>Выбрать букет</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
          </Swiper>

    </section>
  );
}

export default Hero;
